import React, {Fragment, useState, useEffect} from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  maxLength,
  email,
  SelectInput,
  translate,
} from 'react-admin';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import Title from '../Common/Header';
import {Edit} from '../Common/Admin';
import {hasRoles} from '../../utils/auth';
import {compose} from 'recompose';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {CustomToolbar} from '../Common/Admin/CustomToolbar';
import CountryField from '../Common/Field/CountryField';
import TimezoneField from '../Common/Field/TimezoneField';
import {updateUserRoles} from '../User/store/action';

const isRequired = [required()];
const validateName = [required(), maxLength(255)];
const validateDescription = [maxLength(255)];
const validateEmail = [required(), maxLength(200), email()];
const mapStateToProps = ({admin, form}) => ({
  record: form['record-form'],
});

const EditUser = props => {
  const translate = props.translate;

  if (
    !hasRoles([
      'ROLE_CLIENT_ADMIN',
      'ROLE_EUROFOURS_TECHNICIAN',
      'ROLE_SUPERADMIN',
    ])
  ) {
    window.location.replace('/#/not-granted');
  }
  let redirect = 'show';
  if (hasRoles(['ROLE_EUROFOURS_TECHNICIAN'])) {
    if (props.record) {
      if (
        props.record.values.roles.includes('ROLE_USER') ||
        props.record.values.roles.includes('ROLE_EUROFOURS_TECHNICIAN')
      ) {
        window.location.replace('/#/not-granted');
      }
    }
    redirect = '/client-admins';
  }
  if (hasRoles(['ROLE_SUPERADMIN'])) {
    redirect = (props.location.state && props.location.state.from) || '/#';
    if (props.record && props.record.values) {
      const roles = props.record.values.roles;
      if (Array.isArray(roles) && roles.includes('ROLE_EUROFOURS_TECHNICIAN')) {
        const [hasRemoteParamRights, setHasRemoteParamRights] = useState(false);
        useEffect(() => {
          setHasRemoteParamRights(roles.includes('ROLE_REMOTE_PARAMS_EDIT'));
        }, [roles]);

        const handleChange = (event, checked) => {
          const userId = props.record.values.id;
          const updateUserRoles = props.updateUserRoles;
          updateUserRoles({
            id: userId.split('/')[2],
            name: 'ROLE_REMOTE_PARAMS_EDIT',
            value: checked,
          });
          setHasRemoteParamRights(checked);
        };

        return (
          <>
            <Edit
              title={<Title redirect={redirect} {...props} />}
              actions={<Fragment />}
              {...props}>
              <SimpleForm
                toolbar={<CustomToolbar props={props} />}
                redirect={redirect}>
                <TextInput source="firstName" validate={validateName} />
                <TextInput source="lastName" validate={validateName} />
                <TextInput
                  source="email"
                  type="email"
                  validate={validateEmail}
                />
                <SelectInput
                  source="countryCode"
                  choices={[{id: 'FR'}, {id: 'EN'}]}
                  optionText="id"
                  validate={isRequired}
                />
                <SelectInput
                  source="temperatureUnitPreference"
                  label={translate(
                    'custom.commons.temperatureUnitPreference.label',
                  )}
                  choices={[
                    {
                      id: 'C',
                      name: `${translate(
                        'custom.commons.temperatureUnitPreference.celsius',
                      )} - C°`,
                    },
                    {
                      id: 'F',
                      name: `${translate(
                        'custom.commons.temperatureUnitPreference.fahrenheit',
                      )} - F°`,
                    },
                  ]}
                  validate={isRequired}
                  style={{marginBottom: '2em'}}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      value=""
                      checked={hasRemoteParamRights}
                    />
                  }
                  label={
                    <div>
                      <span
                        style={{
                          fontSize: 1.3 + 'em',
                          fontStyle: 'bold',
                          display: 'block',
                        }}>
                        {translate('custom.components.roles.remoteParamsEdit')}
                      </span>
                    </div>
                  }
                />
              </SimpleForm>
            </Edit>
          </>
        );
      }
    }
  }
  return (
    <Edit
      title={<Title redirect={redirect} {...props} />}
      actions={<Fragment />}
      undoable={false} // Warning : this props will break if we update to react-admin V4
      {...props}>
      <SimpleForm toolbar={<CustomToolbar props={props} />} redirect={redirect}>
        <TextInput source="firstName" validate={validateName} />
        <TextInput source="lastName" validate={validateName} />
        <TextInput source="email" type="email" validate={validateEmail} />
        <TextInput source="society" validate={isRequired} />
        <TextInput
          source="description"
          multiline
          validate={validateDescription}
        />
        <TextInput source="phone" inputProps={{maxLength: 15}} />
        <TextInput source="street" validate={isRequired} />
        <TextInput source="city" validate={isRequired} />
        <TextInput
          source="zipcode"
          inputProps={{maxLength: 7}}
          validate={isRequired}
        />
        <CountryField
          source="country"
          validate={isRequired}
          style={{marginTop: '0px'}}
        />
        <TimezoneField
          source="timezone"
          validate={isRequired}
          style={{marginTop: '0px'}}
        />
        <SelectInput
          source="countryCode"
          choices={[{id: 'FR'}, {id: 'EN'}]}
          optionText="id"
          validate={isRequired}
        />
        <SelectInput
          source="temperatureUnitPreference"
          label={translate('custom.commons.temperatureUnitPreference.label')}
          choices={[
            {
              id: 'C',
              name: `${translate(
                'custom.commons.temperatureUnitPreference.celsius',
              )} - C°`,
            },
            {
              id: 'F',
              name: `${translate(
                'custom.commons.temperatureUnitPreference.fahrenheit',
              )} - F°`,
            },
          ]}
          validate={isRequired}
          style={{marginBottom: '2em'}}
        />
      </SimpleForm>
    </Edit>
  );
};

export default compose(
  connect(mapStateToProps, {updateUserRoles}),
  withRouter,
  translate,
)(EditUser);
