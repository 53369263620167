import React, {Fragment, useState} from 'react';
import jwt_decode from 'jwt-decode';
import {
  translate,
  SimpleForm,
  TextInput,
  SaveButton,
  NumberInput,
  SelectInput,
} from 'react-admin';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import {Edit} from '@material-ui/icons';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {editSettings} from '../store/action';

const styles = {
  contentDiv: {
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'center',
    'align-items': 'center',
  },
  contentWarning: {
    'font-weight': 'bold',
    color: '#f14343',
    textAlign: 'center',
  },
};

const MachineSettingsButton = props => {
  const {translate, machine, editSettings} = props;
  const isImpersona = localStorage.getItem('masterToken');

  if (!isImpersona) {
    return <></>;
  }

  if (
    !jwt_decode(isImpersona).roles.some(
      value =>
        value === 'ROLE_REMOTE_PARAMS_EDIT' || value === 'ROLE_SUPERADMIN',
    )
  ) {
    return <></>;
  }

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = data => {
    if (data.param && data.value) {
      const id = machine['@id'].split('/')[2];
      editSettings({id, ...data});
      handleClose();
    }
  };

  return (
    <Fragment>
      <Button color="primary" onClick={() => handleClick()}>
        <Edit /> {translate('custom.buttons.editSettings')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent style={styles.contentDiv}>
          <DialogContentText id="alert-dialog-description">
            <Typography style={styles.contentWarning} variant="h2">
              {translate(`custom.components.machine.editModal.content`)}
            </Typography>
          </DialogContentText>
          <SimpleForm
            style={{
              display: 'flex',
              'justify-content': 'center',
              'flex-direction': 'column',
            }}
            save={handleSubmit}
            toolbar={
              <SaveButton
                submitOnEnter={true}
                style={{verticalAlign: 'center'}}
              />
            }>
            <SelectInput
              source="type"
              label={translate('custom.components.machine.editModal.paramType')}
              choices={[
                {id: 'DOUBLE', name: 'DOUBLE'},
                {id: 'TEXT', name: 'TEXT'},
                {id: 'BOOL', name: 'BOOL'},
                {id: 'IP', name: 'IP'},
                {id: 'TEXTLIST', name: 'TEXTLIST'},
              ]}
            />
            <NumberInput
              source="param"
              label={translate('custom.components.machine.editModal.paramId')}
            />
            <TextInput
              source="value"
              label={translate(
                'custom.components.machine.editModal.paramValue',
              )}
            />
          </SimpleForm>
          <Button onClick={handleClose}>
            {translate(`custom.commons.cancel`)}
          </Button>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  machine: state.machineReducer.machine,
  location: state.router.location.pathname,
});

export default compose(
  translate,
  connect(mapStateToProps, {editSettings}),
)(MachineSettingsButton);
